<div [class.d-none]="loggingIn">
    <button class="btn btn-primary" (click)="login()">Login/Register</button>
</div>
<div [class.d-none]="!loggingIn">
    <button class="btn btn-primary">
        <span class="spinner-border"></span>
        Logging in...
    </button>
</div>

<div [class.d-none]="status.length <= 0">
    <p>{{status}}</p>
</div>