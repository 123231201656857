{{status}}
<table *ngIf="selectedUser < 0" class="table table-striped table-hover">
    <thead>
        <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Admin</th>
            <th>Status URL</th>
            <th>API KEY</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let user of users; let i = index" (click)="selectUser(i)">
            <td>{{user['name']}}</td>
            <td>{{user['email']}}</td>
            <td>{{user['admin']}}</td>
            <td>{{user['URL']}}</td>
            <td>{{user['API_KEY']}}</td>
        </tr>
    </tbody>
</table>
<div *ngIf="selectedUser >= 0 && selectedIpad < 0">
    iPads for {{users[selectedUser]['name']}}
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th>Name</th>
                <th>Model</th>
                <th>Username</th>
                <th>Vendor Id</th>
                <th>Last Update</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let ipad of ipads; let i = index" (click)="selectIpad(i)">
                <td>{{ipad['name']}}</td>
                <td>{{ipad['model']}}</td>
                <td>{{ipad['username']}}</td>
                <td>{{ipad['id']}}</td>
                <td>{{ipad['last_update'] * 1000 | date:'medium'}}</td>
            </tr>
        </tbody>
    </table>
    <button class="btn btn-primary" (click)="selectUser(-1)">Back</button>
</div>

<div *ngIf="selectedIpad >= 0">
    <div class="card container">
    <div class="class-body">
        <strong>Account:</strong> {{users[selectedUser]['name']}}<br>
        <strong>iPad Name:</strong> {{ipads[selectedIpad]['name']}}<br>
        <strong>Vendor Id:</strong> {{ipads[selectedIpad]['id']}}<br>
        <strong>Software:</strong> Breeze Booth for iPad v{{ipads[selectedIpad]['version']}}<br>
        <strong>Username:</strong> {{ipads[selectedIpad]['username']}}<br>
        <strong>Dropbox account:</strong> {{ipads[selectedIpad]['dropbox']}}<br>
        <strong>Sync server:</strong> {{ipads[selectedIpad]['syncServer']}}<br>
        <strong>Last update:</strong> {{ipads[selectedIpad]['last_update'] * 1000 | date:'medium'}} {{daysAgo(selectedIpad)}}<br>
        <strong>Status:</strong> {{ipads[selectedIpad]['status']}}<br>
        <span class="card-text">Battery:</span>
        <div class="container">
            <div class="progress">
                <div class="progress-bar"
                    style="width:{{ipads[selectedIpad]['battery']}}%"
                    [class.bg-warning]="ipads[selectedIpad]['battery'] > 10 && ipads[selectedIpad]['battery'] < 25"
                    [class.bg-danger]="ipads[selectedIpad]['battery'] <= 10">
                    {{ipads[selectedIpad]['battery']}}%
                </div>
            </div>
        </div>
        <span class="card-text">Memory ({{ipads[selectedIpad]['availableInMB'] | memoryFormatter}} free of {{ipads[selectedIpad]['totalInMB'] | memoryFormatter}}):</span>
        <div class="container">
            <div class="progress">
                <div class="progress-bar"
                    style="width:{{memoryPercentage(ipads[selectedIpad])}}%"
                    [class.bg-warning]="ipads[selectedIpad]['availableInMB'] > 1024 && ipads[selectedIpad]['availableInMB'] < 3072"
                    [class.bg-danger]="ipads[selectedIpad]['availableInMB'] <= 1024">
                    {{memoryPercentage(ipads[selectedIpad])}}%
                </div>
            </div>
        </div>
        <strong>Photo sessions today:</strong> {{ipads[selectedIpad]['todayPhotoSessions']}}, emails: {{ipads[selectedIpad]['todayPhotoEmails']}}, texts: {{ipads[selectedIpad]['todayPhotoTexts']}}<br>
        <strong>GIF sessions today:</strong> {{ipads[selectedIpad]['todayGifSessions']}}, emails: {{ipads[selectedIpad]['todayGifEmails']}}, texts: {{ipads[selectedIpad]['todayGifTexts']}}<br>
        <strong>Video sessions today:</strong> {{ipads[selectedIpad]['todayVideoSessions']}}, emails: {{ipads[selectedIpad]['todayVideoEmails']}}, texts: {{ipads[selectedIpad]['todayVideoTexts']}}<br>
        <strong>Total photo sessions:</strong> {{ipads[selectedIpad]['photoSessions']}}, emails: {{ipads[selectedIpad]['photoEmails']}}, texts: {{ipads[selectedIpad]['photoTexts']}}<br>
        <strong>Total GIF sessions:</strong> {{ipads[selectedIpad]['gifSessions']}}, emails: {{ipads[selectedIpad]['gifEmails']}}, texts: {{ipads[selectedIpad]['gifTexts']}}<br>
        <strong>Total Video sessions:</strong> {{ipads[selectedIpad]['videoSessions']}}, emails: {{ipads[selectedIpad]['videoEmails']}}, texts: {{ipads[selectedIpad]['videoTexts']}}<br>
        <strong>Auto login/logout:</strong> {{login}}
    </div>
    <p></p>
    <div class="container">
        <button class="btn btn-primary mr-3" (click)="selectIpad(-1)">Back</button>
        {{ status }}
    </div>
    <div class="container">
        <br>
        <button class="btn btn-sm" (click)="setChartDayOffset(0)" [class.btn-info]="chartDayOffset==0" [class.btn-light]="chartDayOffset!=0">Today {{sessions(0)}}</button>
        <button class="btn btn-sm" (click)="setChartDayOffset(1)" [class.btn-info]="chartDayOffset==1" [class.btn-light]="chartDayOffset!=1">Yesterday {{sessions(1)}}</button>
        <button class="btn btn-sm" (click)="setChartDayOffset(2)" [class.btn-info]="chartDayOffset==2" [class.btn-light]="chartDayOffset!=2">2 days ago {{sessions(2)}}</button>
        <div style="display: block">
            <canvas baseChart
                [datasets]="chartData"
                [labels]="chartLabels"
                [options]="chartOptions"
                [legend]="chartLegend"
                [chartType]="chartType">
            </canvas>
        </div>
    </div>
</div>
