import { AppComponent } from './../app.component';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Pipe, PipeTransform, ViewChild, AfterViewInit } from '@angular/core';
import { Router, RouteConfigLoadEnd } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { timer } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

var ELEMENT_DATA = [
];

@Component({
  selector: 'app-ipad-list',
  templateUrl: './ipad-list.component.html',
  styleUrls: ['./ipad-list.component.css']
})
export class IpadListComponent implements OnInit, AfterViewInit  {
  @ViewChild(MatSort) sort: MatSort;

  status: string = '';
  ipads = [ ];
  ipad_log = [];
  selected: number = -1;
  tableView: boolean = true;
  chartDayOffset: number = 0;

  displayedColumns: string[] = ['name', 'model', 'username', 'id', 'last_update'];
  dataSource = new MatTableDataSource([]);

  public chartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 1
          }
        }
      ]
    }
  };

  public chartLabels = [];
  public chartType = 'bar';
  public chartLegend = true;

  public chartData = [
    {data: [], label: 'Photos'},
    {data: [], label: 'GIFs'},
    {data: [], label: 'Videos'}
  ];

  form = new FormGroup({
    login: new FormControl(),
  });

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  ngOnInit(): void {
    var i;
    this.chartLabels = [];
    for (i = 0; i < 24; i++) {
      if (i < 10) {
        this.chartLabels.push('0' + i + ':00');
      } else {
        this.chartLabels.push(i + ':00');
      }
    }
    this.get_ipads();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      return item[property];
    };
  }
  
  get login() {
    if (this.selected < 0 || this.selected >= this.ipads.length) return 0;
    var known = this.ipads[this.selected]['known'];
    var enabled = this.ipads[this.selected]['enabled'];
    if (known == '0') return 0;
    return enabled == '1' ? 1 : 2;
  }

  get_ipads() : void {
    if (AppComponent.myApp.tokenExpired()) {
      this.router.navigate([ '/login' ]);
      return;
    }
    this.status = 'Updating...';
    var vendorId = this.selected >= 0 ? this.ipads[this.selected]['id'] : '';
    this.http.post('https://dashboard.bb4ipad.com/api/ipads.php', { token: AppComponent.myApp.token() }).subscribe({
      next: data => {
                      if (data['error']) {
                        this.status = data['error'];
                      } else {
                        this.ipads = data['ipads'];

                        // clear 'today' values if last update was not received today
                        var now = new Date();
                        for (i = 0; i < this.ipads.length; i++) {
                          var t = new Date((this.ipads[i]['last_update'] as number) * 1000);
                          if (t.getDay() != now.getDay() || t.getMonth() != now.getMonth() || t.getFullYear() != now.getFullYear()) {
                            this.ipads[i]['todayPhotoSessions'] = '0';
                            this.ipads[i]['todayPhotoEmails'] = '0';
                            this.ipads[i]['todayPhotoTexts'] = '0';
                            this.ipads[i]['todayGifSessions'] = '0';
                            this.ipads[i]['todayGifEmails'] = '0';
                            this.ipads[i]['todayGifTexts'] = '0';
                            this.ipads[i]['todayVideoSessions'] = '0';
                            this.ipads[i]['todayVideoEmails'] = '0';
                            this.ipads[i]['todayVideoTexts'] = '0';
                          }
                        }
                        this.status = '';
                        // if an iPad was selected we need to set selected to the iPad matching its vendor id
                        if (vendorId.length > 0) {
                          var i;
                          for (i = 0; i < this.ipads.length; i++) {
                            if (this.ipads[i]['id'] == vendorId) {
                              this.selected = i;
                              break;
                            }
                          }
                        }
                        this.dataSource = new MatTableDataSource(this.ipads);
                        this.dataSource.sort = this.sort;
                        AppComponent.myApp.updateToken(data);
                      }  
                    },
      error: error => {
          this.status = 'Error listing iPads';
        }
      })
  }

  get_ipad_log(id: string) : void {
    if (AppComponent.myApp.tokenExpired()) {
      this.router.navigate([ '/login' ]);
      return;
    }
    this.status = 'Updating graph...';
    this.chartDayOffset = 0;
    this.http.post('https://dashboard.bb4ipad.com/api/read_ipad_log.php', { token: AppComponent.myApp.token(), vendorId: id }).subscribe({
      next: data => {
          if (data['error']) {
            this.status = data['error'];
          } else {
            this.ipad_log = data['log'];
            this.redrawChart();
            AppComponent.myApp.updateToken(data);
            this.status = '';
          }  
        },
      error: error => {
          this.status = 'Error reading iPad log';
        }
      })
  }

  redrawChart() {
    this.chartData[0].data = [];
    this.chartData[1].data = [];
    this.chartData[2].data = [];
    var logDisplayDate = new Date();
    logDisplayDate.setDate(logDisplayDate.getDate() - this.chartDayOffset);
    var date = logDisplayDate.toISOString().split('T')[0].split('-').join('');  // get date as YYYYMMDD
    var i;
    var dateHour: string;
    for (i=0; i < 24; i++) {
      if (i < 10) {
        dateHour = date + '_0' + i;
      } else {
        dateHour = date + '_' + i;
      }
      var values = this.ipad_log[dateHour];
      if (values) {
        this.chartData[0].data.push(values['photoSessions']);
        this.chartData[1].data.push(values['gifSessions']);
        this.chartData[2].data.push(values['videoSessions']);
      } else {
        this.chartData[0].data.push(0);
        this.chartData[1].data.push(0);
        this.chartData[2].data.push(0);
      }
    }
  }

  memoryPercentage(ipad: object) : number {
    return Math.round(100 * ipad['availableInMB'] / ipad['totalInMB']);
  }

  tableLayout(value: boolean) {
    this.tableView = value;
  }

  setChartDayOffset(value: number) {
    if (value != this.chartDayOffset) {
      this.chartDayOffset = value;
      this.redrawChart();
    }
  }

  sessions(offset: number) : string {
    var logDisplayDate = new Date();
    logDisplayDate.setDate(logDisplayDate.getDate() - offset);
    var date = logDisplayDate.toISOString().split('T')[0].split('-').join('');  // get date as YYYYMMDD
    var i: number;
    var dateHour: string;
    var sessions: number = 0;
    for (i=0; i < 24; i++) {
      if (i < 10) {
        dateHour = date + '_0' + i;
      } else {
        dateHour = date + '_' + i;
      }
      var values = this.ipad_log[dateHour];
      if (values) {
        sessions += values['photoSessions'];
        sessions += values['gifSessions'];
        sessions += values['videoSessions'];
      }
    }
    if (sessions > 0) return '(' + sessions + ')';
    return '';
  }
  
  selectIpad(ipad) {
    for(var i = 0; i < this.ipads.length; i++) {
      if (ipad['id'] == this.ipads[i]['id']) {
        this.selected = i;
        this.get_ipad_log(this.ipads[this.selected]['id']);
        return;
      }
    }
  }

  // go back to iPad summary
  back() {
    this.selected = -1;
  }

  // refresh the list of iPads
  refresh() {
    this.get_ipads();
    if (this.selected >= 0 && this.selected < this.ipads.length) {
      this.get_ipad_log(this.ipads[this.selected]['id']);
    }
  }

  // delete the currently selected iPad
  delete() {
    if (AppComponent.myApp.tokenExpired()) {
      this.router.navigate([ '/login' ]);
      return;
    }
    if (this.selected < 0 || this.selected >= this.ipads.length) return;
    if (!confirm('Are you sure you want to delete all information about this iPad?')) return;
    var id: string = this.ipads[this.selected]['id'];
    this.selected = -1;
    this.status = 'Deleting iPad...';
    this.http.post('https://dashboard.bb4ipad.com/api/delete_ipad.php', {
          token: AppComponent.myApp.token(),
          vendorId: id
        }).subscribe({
      next: data => {
                      if (data['error']) {
                        this.status = data['error'];
                      } else {
                        AppComponent.myApp.updateToken(data);
                        this.get_ipads();
                      }  
                    },
      error: error => {
          this.status = 'Error deleting iPad';
        }
      })
  }

  update() {
    if (AppComponent.myApp.tokenExpired()) {
      this.router.navigate([ '/login' ]);
      return;
    }
    if (this.selected < 0 || this.selected >= this.ipads.length) return;
    var ipad = this.ipads[this.selected];
    var value = this.form.value.login;
    if (value) {
      this.http.post('https://dashboard.bb4ipad.com/api/ipads.php',
            {
              token: AppComponent.myApp.token(),
              vendorId: ipad['id'],
              known: value.indexOf('disabled') >= 0 ? 0 : 1,
              enabled: value.indexOf('login') >= 0 ? 1 : 0
            }).subscribe({
        next: data => {
            if (data['error']) {
              alert('Error updating iPad: ' + data['error']);
            } else {
              if (data['ipad']) {
                this.ipads[this.selected] = data['ipad'];
              }
              AppComponent.myApp.updateToken(data);
              alert('iPad auto login status updated');
            }  
          },
        error: error => {
            alert('Error updating iPad');
          }
        })
    }
  }

  daysAgo(index: number) : string {
    if (index < 0 || index >= this.ipads.length) return '';
    var t = this.ipads[index]['last_update'] as number;
    if (t <= 0) return '';
    var days = ((Date.now() / 1000) - t) / (24 * 60 * 60);
    return days < 2 ? '' : '(' + Math.floor(days) + ' days ago)';
  }

  cardClass(ipad, classStr = '') : string {
    if (ipad['username'].length <= 0) return classStr + ' bg-light text-dark';
    if (ipad['battery'] <= 10 || ipad['availableInMB'] < 512) return classStr + ' bg-danger text-white';
    if (ipad['battery'] <= 25 || ipad['availableInMB'] < 1024) return classStr + ' bg-warning text-dark';
    return classStr + ' bg-success text-white';
  }
}
