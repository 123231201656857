import {Pipe, PipeTransform} from '@angular/core';
@Pipe ({
   name : 'memoryFormatter'
})
export class MemoryFormatterPipe implements PipeTransform {
    transform(mb : number) : string {
        if (mb < 1024) {
            return mb.toString() + 'MB';
        }
        let gb = mb / 1024;
        if (gb < 20) {
            return gb.toFixed(1) + 'GB';
        }
        return Math.round(gb).toLocaleString() + 'GB';
    }
}