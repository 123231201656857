import { AppComponent } from './../app.component';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loggingIn: boolean = false;
  status: string = '';
 
  constructor(
    private location: Location,
    private router: Router,
    private http: HttpClient
    ) { }

  ngOnInit(): void {
    // redirect to https if protocol is http and host is not localhost
    if (location.protocol == 'http:' && location.hostname != 'localhost') {
      window.location.href = 'https://' + location.host + location.pathname;
    } else if (AppComponent.myApp.tokenExpired()) {
      this.swapCodeForToken();
    } else {
      // still logged in: display ipads
      this.router.navigate([ '/ipads' ]);
    }
  }

  login() {
    window.location.href = 'https://ipad.auth.eu-central-1.amazoncognito.com/login?' +
                              'client_id=1f1rmjup6v1d5ig2q3sdd2b6tu&' +
                              'response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&' +
                              'redirect_uri=' + AppComponent.myApp.host + '/login';
  }

  swapCodeForToken(): void {
    //const code = +this.route.snapshot.paramMap.get('code');
    var search = location.search;
    if (search.indexOf('?code=') == 0) {
      var access_code = search.substring(6);
      this.loggingIn = true;
      this.http.post('https://dashboard.bb4ipad.com/api/login.php', { code: access_code, host: AppComponent.myApp.host }).subscribe({
        next: data => {
                        if (data['error']) {
                          this.status = data['error'];
                        } else {
                          AppComponent.myApp.updateToken(data);
                          this.status = 'Logged in as ' + data['username'];
                          this.router.navigate([ '/ipads' ]);
                        }  
                        this.loggingIn = false;
                      },
        error: error => {
            this.status = 'Error logging in';
            this.loggingIn = false;
          }
        })
    } else {
      this.loggingIn = false;
    }
  }

}
