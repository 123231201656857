import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from './../app.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  status: string = '';
  users = [];
  ipads = [];
  ipad_log = [];
  selectedUser: number = -1;
  selectedIpad: number = -1;

  chartDayOffset: number = 0;

  public chartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 1
          }
        }
      ]
    }
  };

  public chartLabels = [];
  public chartType = 'bar';
  public chartLegend = true;

  public chartData = [
    {data: [], label: 'Photos'},
    {data: [], label: 'GIFs'},
    {data: [], label: 'Videos'}
  ];

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  ngOnInit(): void {
    var i;
    this.chartLabels = [];
    for (i = 0; i < 24; i++) {
      if (i < 10) {
        this.chartLabels.push('0' + i + ':00');
      } else {
        this.chartLabels.push(i + ':00');
      }
    }
    this.get_users();
  }

  get_users() {
    if (AppComponent.myApp.tokenExpired()) {
      this.router.navigate([ '/login' ]);
      return;
    }
    this.status = 'Updating...';
    this.http.post('https://dashboard.bb4ipad.com/api/users.php', { token: AppComponent.myApp.token() }).subscribe({
      next: data => {
                      if (data['error']) {
                        this.status = data['error'];
                      } else {
                        this.users = data['users'];
                        this.status = '';
                        AppComponent.myApp.updateToken(data);
                      }  
                    },
      error: error => {
          this.status = 'Error listing users';
        }
      })  
  }

  get_ipads(ownerId: string) : void {
    if (AppComponent.myApp.tokenExpired()) {
      this.router.navigate([ '/login' ]);
      return;
    }
    this.status = 'Updating...';
    var vendorId = this.selectedIpad >= 0 ? this.ipads[this.selectedIpad]['id'] : '';
    this.http.post('https://dashboard.bb4ipad.com/api/ipads.php', {
        token: AppComponent.myApp.token(),
        id: ownerId
      }).subscribe({
      next: data => {
                      if (data['error']) {
                        this.status = data['error'];
                      } else {
                        this.ipads = data['ipads'];
                        this.status = '';
                        // if an iPad was selected we need to set selected to the iPad matching its vendor id
                        if (vendorId.length > 0) {
                          var i;
                          for (i = 0; i < this.ipads.length; i++) {
                            if (this.ipads[i]['id'] == vendorId) {
                              this.selectedIpad = i;
                              break;
                            }
                          }
                        }
                        AppComponent.myApp.updateToken(data);
                      }  
                    },
      error: error => {
          this.status = 'Error listing iPads';
        }
      })
  }

  selectUser(index: number) : void {
    if (index >= 0 && index <= this.users.length && index != this.selectedUser) {
      this.get_ipads(this.users[index]['id']);
      this.selectedUser = index;
    } else {
      this.selectedUser = -1;
      this.selectedIpad = -1;
      this.ipads = [];
    }
  }

  selectIpad(index: number) {
    if (index >= 0 && index < this.ipads.length) {
      this.selectedIpad = index;
      this.get_ipad_log(this.ipads[this.selectedIpad]['id']);
    } else {
      this.selectedIpad = -1;
    }
  }

  daysAgo(index: number) : string {
    if (index < 0 || index >= this.ipads.length) return '';
    var t = this.ipads[index]['last_update'] as number;
    if (t <= 0) return '';
    var days = ((Date.now() / 1000) - t) / (24 * 60 * 60);
    return days < 2 ? '' : '(' + Math.floor(days) + ' days ago)';
  }

  memoryPercentage(ipad: object) : number {
    return Math.round(100 * ipad['availableInMB'] / ipad['totalInMB']);
  }

  get login() : string {
    if (this.selectedIpad < 0 || this.selectedIpad >= this.ipads.length) 'unknown';
    var known = this.ipads[this.selectedIpad]['known'];
    var enabled = this.ipads[this.selectedIpad]['enabled'];
    if (known == '0') 'disabled';
    return enabled == '1' ? 'auto login' : 'auto logout';
  }

  get_ipad_log(id: string) : void {
    if (AppComponent.myApp.tokenExpired()) {
      this.router.navigate([ '/login' ]);
      return;
    }
    this.status = 'Updating graph...';
    this.chartDayOffset = 0;
    this.http.post('https://dashboard.bb4ipad.com/api/read_ipad_log.php', { token: AppComponent.myApp.token(), vendorId: id }).subscribe({
      next: data => {
          if (data['error']) {
            this.status = data['error'];
          } else {
            this.ipad_log = data['log'];
            this.redrawChart();
            AppComponent.myApp.updateToken(data);
            this.status = '';
          }  
        },
      error: error => {
          this.status = 'Error reading iPad log';
        }
      })
  }

  sessions(offset: number) : string {
    var logDisplayDate = new Date();
    logDisplayDate.setDate(logDisplayDate.getDate() - offset);
    var date = logDisplayDate.toISOString().split('T')[0].split('-').join('');  // get date as YYYYMMDD
    var i: number;
    var dateHour: string;
    var sessions: number = 0;
    for (i=0; i < 24; i++) {
      if (i < 10) {
        dateHour = date + '_0' + i;
      } else {
        dateHour = date + '_' + i;
      }
      var values = this.ipad_log[dateHour];
      if (values) {
        sessions += values['photoSessions'];
        sessions += values['gifSessions'];
        sessions += values['videoSessions'];
      }
    }
    if (sessions > 0) return '(' + sessions + ')';
    return '';
  }

  setChartDayOffset(value: number) {
    if (value != this.chartDayOffset) {
      this.chartDayOffset = value;
      this.redrawChart();
    }
  }

  redrawChart() {
    this.chartData[0].data = [];
    this.chartData[1].data = [];
    this.chartData[2].data = [];
    var logDisplayDate = new Date();
    logDisplayDate.setDate(logDisplayDate.getDate() - this.chartDayOffset);
    var date = logDisplayDate.toISOString().split('T')[0].split('-').join('');  // get date as YYYYMMDD
    var i;
    var dateHour: string;
    for (i=0; i < 24; i++) {
      if (i < 10) {
        dateHour = date + '_0' + i;
      } else {
        dateHour = date + '_' + i;
      }
      var values = this.ipad_log[dateHour];
      if (values) {
        this.chartData[0].data.push(values['photoSessions']);
        this.chartData[1].data.push(values['gifSessions']);
        this.chartData[2].data.push(values['videoSessions']);
      } else {
        this.chartData[0].data.push(0);
        this.chartData[1].data.push(0);
        this.chartData[2].data.push(0);
      }
    }
  }
}
