<nav class="navbar navbar-expand-md bg-dark navbar-dark">
    <a class="navbar-brand">Dashboard</a>
    <button class="navbar-toggler" type="button" (click)="toggleCollapse()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse" [class.collapse]="navbarCollapsed" id="collapsibleNavbar">
      <ul class="navbar-nav" *ngIf="!isLoginPage()">
        <li class="nav-item">
          <a class="nav-link" (click)="ipads()">iPads</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="settings()">Settings</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="logout()">Logout</a>
        </li>    
      </ul>
      <ul class="navbar-nav" *ngIf="isLoginPage()">
        <li class="nav-item">
          <a class="nav-link">Logged out</a>
        </li>
      </ul>
    </div>  
  </nav>
  <br>