import { QrDialogComponent } from './../qr-dialog/qr-dialog.component';
import { SettingsValidators } from './settings.validators';
import { AppComponent } from './../app.component';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  form = new FormGroup({
    username: new FormControl(),
    password: new FormControl(),
    interval: new FormControl('0', [
      Validators.required,
      SettingsValidators.intervalTooShort
    ])
  });

  get interval() {
    return this.form.get('interval');
  }

  settings: object;
  status: string = '';
 
  constructor(
    private location: Location,
    private router: Router,
    private http: HttpClient,
    public qrDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getSettings();
  }

  getSettings() : void {
    if (AppComponent.myApp.tokenExpired()) {
      this.router.navigate([ '/login' ]);
      return;
    }
    this.status = 'Updating...';
    this.http.post('https://dashboard.bb4ipad.com/api/settings.php', { token: AppComponent.myApp.token() }).subscribe({
      next: data => {
          if (data['error']) {
            this.status = data['error'];
          } else {
            this.settings = data['settings'];
            this.form.get('username').setValue(this.settings['username']);
            this.form.get('password').setValue(this.settings['password']);
            this.form.get('interval').setValue(this.settings['updateInterval']);
            this.status = '';
            AppComponent.myApp.updateToken(data);
          }  
        },
      error: error => {
          this.status = 'Error getting settings';
        }
      })
    }

    newPassword() {
      if (AppComponent.myApp.tokenExpired()) {
        this.router.navigate([ '/login' ]);
        return;
      }
      if (confirm('creating a new password will replace the old password. Any iPads using the old password will need to be updated.\nDo you wish to continue?')) {
        this.status = 'Updating status URL password...';
        this.http.post('https://dashboard.bb4ipad.com/api/settings.php',
              {
                token: AppComponent.myApp.token(),
                cmd: 'new API_KEY'
              }).subscribe({
          next: data => {
              if (data['error']) {
                this.status = data['error'];
              } else {
                this.settings['API_KEY'] = data['settings']['API_KEY'];
                this.status = '';
                AppComponent.myApp.updateToken(data);
                alert('Created new status URL password: ' + this.settings['API_KEY']);
              }  
            },
          error: error => {
              this.status = 'Error creating new status URL password';
            }
          })  
      }
    }

    submit() {
      if (AppComponent.myApp.tokenExpired()) {
        this.router.navigate([ '/login' ]);
        return;
      }
      if (!this.form.valid) return;
      this.status = 'Updating...';
      this.http.post('https://dashboard.bb4ipad.com/api/settings.php',
            {
              token: AppComponent.myApp.token(),
              username: this.form.get('username').value,
              password: this.form.get('password').value,
              updateInterval: this.form.get('interval').value
            }).subscribe({
        next: data => {
            if (data['error']) {
              this.status = data['error'];
            } else {
              this.settings = data['settings'];
              this.form.get('username').setValue(this.settings['username']);
              this.form.get('password').setValue(this.settings['password']);
              this.form.get('interval').setValue(this.settings['updateInterval']);
              this.status = '';
              AppComponent.myApp.updateToken(data);
              alert('Settings updated');
            }  
          },
        error: error => {
            this.status = 'Error getting settings';
          }
        })
    }

  admin() {
    if (AppComponent.myApp.tokenExpired()) {
      this.router.navigate([ '/login' ]);
      return;
    }
    this.router.navigate([ '/admin' ]);
  }

  statusUrlQR() : string {
    var interval = this.form.get('interval').value;
    var cmd = { statusUrl: this.settings['URL'], statusUrlPassword: this.settings['API_KEY'] };
    if (interval >= 60) {
      cmd['statusUrlInterval'] = interval;
    }
    var json = JSON.stringify(cmd);
    console.log(json);
    var uri = encodeURIComponent('https://breezesys.com?admin=' + encodeURIComponent(JSON.stringify(cmd)));
    return 'https://dashboard.bb4ipad.com/qr2png.php?url=' + uri;
  }

  showStatusUrlQR() {
    const dialogRef = this.qrDialog.open(QrDialogComponent, {
      data: { url: this.statusUrlQR() }
    });    
  }
}
