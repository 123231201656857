<p>{{status}}</p>
<div *ngIf="settings">
    <button class="btn btn-danger" (click)="admin()" *ngIf="settings['admin'] != 0">Admin</button>
    <p>
        <strong>Username:</strong> {{settings['name']}}<br>
        <strong>Email:</strong> {{settings['email']}}
    </p>
    <hr>
    <p>
      <strong>Status URL:</strong> {{settings['URL']}}<br>
      <strong>Status URL password:</strong> {{settings['API_KEY']}}
    </p>
    <p>
      <button class="btn btn-primary mr-2" (click)="newPassword()">New password</button>
      <button class="btn btn-primary" (click)="showStatusUrlQR()">Show QR code</button>
    </p>
    <form [formGroup]="form">
      <div class="form-group">
        <label for="username">iPad login name:</label>
        <input
          formControlName="username"
          id="username"
          type="text"
          class="form-control">
      </div>
      <div class="form-group">
        <label for="password">iPad login password:</label>
        <input
          formControlName="password"
          id="password"
          type="password"
          class="form-control">
      </div>
      <div class="form-group">
        <label for="interval">Status URL update interval (secs):</label>
        <input
          formControlName="interval"
          id="interval"
          type="text"
          class="form-control">
          <div class="alert alert-danger" *ngIf="!interval.valid">
            <div *ngIf="interval.errors.required">Please enter an update interval</div>
            <div *ngIf="interval.errors.intervalTooShort">Update interval must be 0 (disabled) or at least 60 seconds</div> 
          </div>
      </div>
      <button class="btn btn-primary" [disabled]="!form.valid" (click)="submit()">Update</button>
    </form>
  </div>
