import { AppComponent } from './../app.component';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  public navbarCollapsed = true;

  constructor(
    private location: Location,
    private router: Router,
    private http: HttpClient
    ) { }

  ngOnInit(): void {
  }

  toggleCollapse() {
    this.navbarCollapsed = !this.navbarCollapsed;
  }

  settings() {
    this.navbarCollapsed = true;
    this.router.navigate([ '/settings' ]);
  }

  ipads() {
    this.navbarCollapsed = true;
    this.router.navigate([ '/ipads' ]);
  }

  logout() {
    this.http.get('https://ipad.auth.eu-central-1.amazoncognito.com/logout?client_id=1f1rmjup6v1d5ig2q3sdd2b6tu').subscribe(
        data => console.log("Logout: " + data),
        error => console.error('There was an error: ', error)
        );

    //this.http.get('https://ipad.auth.eu-central-1.amazoncognito.com/logout?' + 
    //              'client_id=1f1rmjup6v1d5ig2q3sdd2b6tu&' + 
    //              'logout_uri=' + AppComponent.myApp.host + '/logout').subscribe(data => {
    //   console.log("Logout: " + data);
    //  });
    AppComponent.myApp.clearToken();
    this.navbarCollapsed = true;
    this.router.navigate([ '/login' ]);
  }

  public isLoginPage() : boolean {
    return location.pathname == '/login';
  }
}
