import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit } from '@angular/core';
import { isDevMode } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'dashboard';
  static myApp;
  private access_token = { t: 0, token: '' };
  host = 'https://dashboard.bb4ipad.com';

  constructor(
    private cookieService: CookieService,
    private location: Location
    )
  {
  }

  ngOnInit() {
    this.host = location.origin;
    console.log(location.origin);
    AppComponent.myApp = this;
    let cc = window as any;
       cc.cookieconsent.initialise({
         palette: {
           popup: {
             background: "#164969"
           },
           button: {
             background: "#ffe000",
             text: "#164969"
           }
         },
         theme: "classic",
         content: {
           message: 'This website uses cookies to ensure you get the best experience on our website',
           dismiss: 'Got it!',
           link: 'Learn more',
           href: 'https://www.breezesys.com/cookie-policy/' 
         }
       });
  }

  tokenExpired() : boolean {
      if (this.access_token.t == 0 && this.cookieService.check('access_token')) {
        this.access_token = JSON.parse(this.cookieService.get('access_token'));
      }
      if (this.access_token.token.length <= 0) return true;
      var d = new Date();
      var n = d.getTime() / 1000;
      return this.access_token.t + 3600 < n; 
  }

  token() : string {
      return this.access_token.token;
  }

  updateToken(token: object) : void {
     if (token['t'] && token['token']) {
         this.access_token.t = token['t'];
         this.access_token.token = token['token'];
         this.cookieService.set('access_token', JSON.stringify(this.access_token), Date.now(), '/');
     } else {
         this.access_token.t = 0;
         this.access_token.token = '';
     }
  }

  clearToken() : void {
    this.access_token.t = 0;
    this.access_token.token = '';
    this.cookieService.delete('access_token');
  }
}
