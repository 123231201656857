<div *ngIf="selected < 0">
    <button class="btn btn-info" (click)='get_ipads()'>Refresh</button> {{ status }}
    Display as: <div class="form-check-inline">
        <label class="form-check-label">
            <input type="radio" class="form-check-input" name="optradio" (click)="tableLayout(true)" [checked]="tableView">Table
        </label>
    </div>
    <div class="form-check-inline">
        <label class="form-check-label">
            <input type="radio" class="form-check-input" name="optradio" (click)="tableLayout(false)" [checked]="!tableView">Cards
        </label>
    </div>
</div>
<p></p>
<table [hidden]="!tableView || selected >= 0" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <!-- Position Column -->
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
      <td mat-cell *matCellDef="let element"> {{element.username}} </td>
    </ng-container>
  
    <!-- Name column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
  
    <!-- Model column -->
    <ng-container matColumnDef="model">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Model </th>
        <td mat-cell *matCellDef="let element"> {{element.model}} </td>
      </ng-container>

      <!-- VendorId column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Vendor id </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>
  
    <!-- Last update column -->
    <ng-container matColumnDef="last_update">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Update </th>
      <td mat-cell *matCellDef="let element"> {{element.last_update * 1000 | date:'medium'}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; let even = even; columns: displayedColumns;" [ngClass]="{gray: even}" (click)="selectIpad(row)"></tr>
</table>

<div *ngIf="!tableView && selected < 0" class="container-fluid">
    <div class="row">
        <div *ngFor="let ipad of ipads, let i = index"
                [class]="cardClass(ipad, 'card container col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-2')"
                (click)="selectIpad(ipad)">
            <div class="class-body">
                <span class="card-title">{{ipad['name']}}: {{ipad['model']}}</span>
                <br>
                <span class="card-text">Battery:</span>
                <div class="container">
                    <div class="progress">
                        <div class="progress-bar" style="width:{{ipad['battery']}}%">{{ipad['battery']}}%</div>
                    </div>
                </div>
                <span class="card-text">Memory ({{ipad['availableInMB'] | memoryFormatter}} free):</span>
                <div class="container">
                    <div class="progress">
                        <div class="progress-bar" style="width:{{memoryPercentage(ipad)}}%">{{memoryPercentage(ipad)}}%</div>
                    </div>
                </div>
                <p class="card-text">
                Username: {{ipad['username']}}<br>
                Status: {{ipad['status']}}<br>
                Id: {{ipad['id']}}<br>
                Last update: {{ipad['last_update'] * 1000 | date:'medium'}}  {{daysAgo(i)}}<br>
                </p>
            </div>
        </div>    
        <div class="container col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"></div>
        <div class="container col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"></div>
        <div class="container col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"></div>
    </div>
</div>

<div *ngIf="selected >= 0" class="container-fluid">
    <form [formGroup]="form">
        <div class="card container">
        <div class="class-body">
            <strong>iPad Name:</strong> {{ipads[selected]['name']}}<br>
            <strong>iPad Model:</strong> {{ipads[selected]['model']}}<br>
            <strong>Vendor Id:</strong> {{ipads[selected]['id']}}<br>
            <strong>Software:</strong> Breeze Booth for iPad v{{ipads[selected]['version']}}<br>
            <strong>Username:</strong> {{ipads[selected]['username']}}<br>
            <span *ngIf="ipads[selected]['syncMethod']"><strong>Sync method:</strong> {{ipads[selected]['syncMethod']}}<br></span>
            <strong>Dropbox account:</strong> {{ipads[selected]['dropbox']}}<br>
            <span *ngIf="ipads[selected]['dropbox'] && ipads[selected]['syncMethod']=='dropbox'"><strong>Dropbox sync prefix:</strong> {{ipads[selected]['dropboxPrefix']}}<br></span>
            <span *ngIf="ipads[selected]['syncMethod'] != 'dropbox'"><strong>Sync server:</strong> {{ipads[selected]['syncServer']}}<br></span>
            <strong>Last update:</strong> {{ipads[selected]['last_update'] * 1000 | date:'medium'}} {{daysAgo(selected)}}<br>
            <strong>Status:</strong> {{ipads[selected]['status']}}<br>
            <span class="card-text">Battery:</span>
            <div class="container">
                <div class="progress">
                    <div class="progress-bar"
                        style="width:{{ipads[selected]['battery']}}%"
                        [class.bg-warning]="ipads[selected]['battery'] > 10 && ipads[selected]['battery'] < 25"
                        [class.bg-danger]="ipads[selected]['battery'] <= 10">
                        {{ipads[selected]['battery']}}%
                    </div>
                </div>
            </div>
            <span class="card-text">Memory ({{ipads[selected]['availableInMB'] | memoryFormatter}} free of {{ipads[selected]['totalInMB'] | memoryFormatter}}):</span>
            <div class="container">
                <div class="progress">
                    <div class="progress-bar"
                        style="width:{{memoryPercentage(ipads[selected])}}%"
                        [class.bg-warning]="ipads[selected]['availableInMB'] > 1024 && ipads[selected]['availableInMB'] < 3072"
                        [class.bg-danger]="ipads[selected]['availableInMB'] <= 1024">
                        {{memoryPercentage(ipads[selected])}}%
                    </div>
                </div>
            </div>
            <strong>Photo sessions today:</strong> {{ipads[selected]['todayPhotoSessions']}}, emails: {{ipads[selected]['todayPhotoEmails']}}, texts: {{ipads[selected]['todayPhotoTexts']}}<br>
            <strong>GIF sessions today:</strong> {{ipads[selected]['todayGifSessions']}}, emails: {{ipads[selected]['todayGifEmails']}}, texts: {{ipads[selected]['todayGifTexts']}}<br>
            <strong>Video sessions today:</strong> {{ipads[selected]['todayVideoSessions']}}, emails: {{ipads[selected]['todayVideoEmails']}}, texts: {{ipads[selected]['todayVideoTexts']}}<br>
            <strong>Total photo sessions:</strong> {{ipads[selected]['photoSessions']}}, emails: {{ipads[selected]['photoEmails']}}, texts: {{ipads[selected]['photoTexts']}}<br>
            <strong>Total GIF sessions:</strong> {{ipads[selected]['gifSessions']}}, emails: {{ipads[selected]['gifEmails']}}, texts: {{ipads[selected]['gifTexts']}}<br>
            <strong>Total Video sessions:</strong> {{ipads[selected]['videoSessions']}}, emails: {{ipads[selected]['videoEmails']}}, texts: {{ipads[selected]['videoTexts']}}<br>
                <div class="form-group">
                    <label for="login">Auto login/logout:</label>
                    <select
                        formControlName="login"
                        id="login"
                        class="form-control">
                        <option [selected]="login == 0">disabled</option>
                        <option [selected]="login == 1">auto login</option>
                        <option [selected]="login == 2">auto logout</option>
                    </select>
                </div>
            </div>
        </div>
        <p></p>
        <div class="container">
            <button class="btn btn-primary mr-2" (click)="back()">Back</button>
            <button class="btn btn-primary mr-2" (click)="refresh()">Refresh</button>
            <button class="btn btn-primary mr-2" [disabled]="form.pristine" (click)="update()">Update</button>
            <button class="btn btn-danger" (click)="delete()">Delete</button>
            {{ status }}
        </div>
    </form>
    <div class="container">
        <br>
        <button class="btn btn-sm" (click)="setChartDayOffset(0)" [class.btn-info]="chartDayOffset==0" [class.btn-light]="chartDayOffset!=0">Today {{sessions(0)}}</button>
        &nbsp;<button class="btn btn-sm" (click)="setChartDayOffset(1)" [class.btn-info]="chartDayOffset==1" [class.btn-light]="chartDayOffset!=1">Yesterday {{sessions(1)}}</button>
        &nbsp;<button class="btn btn-sm" (click)="setChartDayOffset(2)" [class.btn-info]="chartDayOffset==2" [class.btn-light]="chartDayOffset!=2">2 days ago {{sessions(2)}}</button>

        <div style="display: block">
            <canvas baseChart
                [datasets]="chartData"
                [labels]="chartLabels"
                [options]="chartOptions"
                [legend]="chartLegend"
                [chartType]="chartType">
            </canvas>
        </div>
    </div>
</div>
